.p-page {
  .banner {
    position: relative;
    height: 3.52rem;
    background: #eee;
    .swiper-slide {
      width: 100%;
      height: 3.52rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .slogan {
      position: absolute;
      top: .251rem;
      right: .8rem;
      z-index: 99;
      width: 1.6rem;
      height: 3rem;
      background: rgba(125,0,0,.8);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #fff;
        width: .2rem;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        font-style: italic;
        font-size: .26rem;
        font-weight: bold;
        &:first-child {
          margin-right: .3rem;
        }
        .t1 {
          margin-bottom: .16rem;
        }
      }
    }
  }
  .sec1 {
    padding-top: .4rem;
    display: flex;
    img {
      margin-right: .2rem;
      width: 2.7rem;
      height: 3.6rem;
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .tel {
      color: #c23e3f;
      font-weight: bold;
      a {
        color: #c23e3f;
        font-style: italic;
      }
    }
  }
  .company {
    padding-top: 0;
    .c-box {
      position: relative;
      margin-bottom: .2rem;
      width: 100%;
      height: 1.97rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.2);
      }
      .t1 {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-size: .32rem;
        font-weight: 700;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
      }
      .t2 {
        position: relative;
        z-index: 1;
        font-size: .24rem;
        font-weight: 700;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
      }
      .dot {
        margin: 0 .06rem;
        width: .1rem;
        height: .1rem;
        border-radius: 50%;
        background: #fff;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
      }
    }
    .c-sx {
      background: url('../../assets/c-sx.jpg') no-repeat center / 100% 100%;
    }
    .c-bj {
      background: url('../../assets/c-bj.jpg') no-repeat center / 100% 100%;
    }
    .c-qh {
      background: url('../../assets/c-qh.jpg') no-repeat center / 100% 100%;
    }
    .c-fz {
      background: url('../../assets/c-fz.jpg') no-repeat center / 100% 100%;
    }
    .c-dg {
      background: url('../../assets/c-dg.jpg') no-repeat center / 100% 100%;
    }
    .c-ll {
      background: url('../../assets/c-ll.jpg') no-repeat center / 100% 100%;
    }
  }
  .pic-map {
    display: block;
    margin: 0 auto .6rem;
    width: 6.36rem;
  }
}