.c-page {
  .map-box {
    position: relative;
    width: 100%;
    height: #e2e2e2;
  }
  .map {
    display: block;
    width: 100%;
    // width: 3.2rem;
  }
  .map-tag {
    position: absolute;
    top: 1.8rem;
    left: 1.88rem;
    p {
      position: relative;
      padding: 0 .24rem;
      font-size: .24rem;
      color: #fff;
      line-height: .4rem;
      background: #d64648;
      border-radius: .2rem;
      &::after {
        content: '';
        width: .14rem;
        height: .14rem;
        background: #d64648;
        transform: rotate(45deg);
        bottom: -.07rem;
        left: 50%;
        margin-left: -.07rem;
        position: absolute;
      }
    }
  }
  .map-tag1 {
    top: 1.78rem;
    left: 1.95rem;
  }
  .map-circle {
    position: absolute;
    bottom: -.45rem;
    left: 50%;
    border: .04rem solid #d64648;
    border-radius: 50%;
    background-color: #fff;
    width: .2rem;
    height: .2rem;
    box-sizing: border-box;
    transform: translate(-50%,-50%);
  }
  .name {
    margin-bottom: .2rem;
    font-size: .32rem;
    color: #333;
    line-height: .4rem;
  }
  .txt {
    font-size: .26rem;
    line-height: .48rem;
    color: #666;
  }
}