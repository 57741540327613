* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}

html {
  scroll-behavior: smooth;
}

html, body {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

body {
  font-size: 0.28rem;
  color: #333;
  /* overflow-x: hidden; */
  /* -webkit-overflow-scrolling: touch; */
}

ul {
  list-style: none;
}

.zr-page-sec {
  padding-top: .78rem;
  padding-bottom: .62rem;
  width: 6.7rem;
  margin: 0 auto;
}
.zr-page-sec .sec-title {
  margin-bottom: .64rem;
  color: #444;
  font-size: .32rem;
  line-height: .4rem;
  font-weight: 700;
}
.zr-page-sec .sec-desc {
  color: #555;
  font-size: .28rem;
  line-height: .42rem;
  text-align: justify;
}

.PhotoView__PhotoBox {
  width: 100% !important;
  height: 50px !important;
}
