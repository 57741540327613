.zr-wrapper {
  height: 100vh;
  transition: all .4s cubic-bezier(.4,.01,.165,.99);
  &.open {
    transform: translate3d(-4rem,0,0);
    .zr-container {
      box-shadow: 0 0 35px 0 rgba(71, 71, 71, .34);
    }
    .zr-router {
      overflow-y: hidden;
    }
  }
}

.zr-container {
  position: relative;
  height: 100%;
  z-index: 1;
  .zr-router {
    height: calc(100% - 1rem);
    overflow-y: auto;
  }
}

.zr-side {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: .8rem;
  width: 4rem;
  transform: translate3d(100%,0,0);
  background-color: #fff;
  text-align: right;
  li {
    height: .9rem;
    line-height: .9rem;
    border-bottom: #e5e5e5 .01rem solid;
    display: block;
    color: #9d9d9d;
    position: relative;
    padding: 0 .4rem;
    font-size: .26rem;
    &.active {
      a { color: #333; }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      color: #9d9d9d;
      text-decoration: none;
    }
  }
}