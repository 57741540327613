.zr-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1rem;
  width: 100vw;
  background: rgba(255, 255, 255, .9);
  box-shadow: 0 0 .2rem rgba(0, 0, 0, .1);
  &-logo {
    margin-left: .4rem;
    display: flex;
    align-items: center;
    font-size: .36rem;
    font-weight: 700;
    color: #333;
    text-decoration: none;
    .dot {
      margin: 0 .2rem;
      width: .12rem;
      height: .12rem;
      border-radius: 50%;
      background: #333;
    }
  }
  &-menu {
    width: 1.2rem;
    height: 1rem;
    &>div {
      position: relative;
      margin-left: .4rem;
      margin-top: .35rem;
      width: .4rem;
      height: .3rem;
    }
    .rect {
      width: 100%;
      height: .04rem;
      background: #333;
      position: absolute;
      top: 0;
      transition: all ease .4s;
      border-radius: .02rem;
      &.middle {
        transform: translateY(.13rem);
      }
      &.bottom {
        transform: translateY(.26rem);
      }
    }
    &.close {
      .top {
        transform: translateY(.13rem) rotate(45deg);
      }
      .middle {
        opacity: 0;
      }
      .bottom {
        transform: translateY(.13rem) rotate(-45deg);
      }
    }
  }
}