.w-page {
  .pic1 {
    display: block;
    width: 100%;
  }
  .tags-list {
    margin-top: .4rem;
    li {
      margin-top: .2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: .6rem;
      font-size: .26rem;
      span {
        width: 45%;
        text-align: center;
        border: 1px solid #5b5b5b;
        border-radius: .1rem;
      }
    }
  }
  .pic2 {
    display: block;
    width: 100%;
    // height: 3rem;
    margin-bottom: .2rem;
  }
}