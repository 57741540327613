.about-page {
  .pic-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-bottom: .35rem;
      width: 3.3rem;
      &.l1 {
        width: 6.7rem;
        .sub-pic {
          width: 6.7rem;
        }
      }
    }
  }
  .sub-pic {
    width: 3.3rem;
    height: 2.26rem;
  }
  .sub-tit {
    display: block;
    margin-top: .2rem;
    line-height: .3rem;
    font-size: .24rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}