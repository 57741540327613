.index-page {
  .banner {
    height: 3.52rem;
    background: #eee;
    .swiper-slide {
      width: 100%;
      height: 3.52rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-yw {
    height: 3rem;
    .swiper-slide {
      position: relative;
      height: 3rem;
      transition: all .3s linear;
      &>div {
        width: 2.23rem;
        height: 3rem;
        img {
          display: block;
          width: 100%;
          height: 100%;
          transform: scale(.9);
          transition: all .3s linear;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        background: rgba(198, 79, 79, .8);
        transition: all .3s linear;
      }
      p {
        position: absolute;
        bottom: .4rem;
        left: .2rem;
        z-index: 1;
        color: #fff;
        opacity: 0;
        transition: all .3s linear;
      }
    }
    .swiper-slide-active {
      &>div img {
        transform: scale(1);
      }
      &::before {
        opacity: 1;
      }
      p {
        opacity: 1;
      }
    }
  }
  .dp1 {
    display: block;
    width: 100%;
    height: 3rem;
  }
}
