.zr-footer {
  padding: .6rem;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 5rem;
  background: #0d0d0d;
  &-left {
    padding-right: .6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    border-right: .02rem solid #fff;
    .logo {
      margin-bottom: .4rem;
      width: 1.2rem;
    }
    .txt {
      width: 1.2rem;
    }
    .code {
      margin-top: .2rem;
      width: 1.2rem;
    }
  }
  &-right {
    padding-left: .8rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: .26rem;
    .email {
      display: flex;
      align-items: center;
      img {
        margin-left: .6rem;
      }
    }
  }
}